import utility from '@/common/utility'
import auth from '@/common/auth'

export default class MeterageListModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.meterageListGuid
      this.meterageListGuid = props.meterageListGuid
      this.meterageId = props.meterageId
      this.parentId = props.parentId
      this.chapter = props.chapter
      this.meterageCode = props.meterageCode
      this.meterageDisplayCode = props.meterageDisplayCode
      this.meterageName = props.meterageName
      this.meterageFullName = props.meterageFullName
      this.unit = props.unit
      this.num = props.num
      this.price = props.price
      this.amount = props.amount
      this.tenderGuid = props.tenderGuid
      this.changeDirectiveGuid = props.changeDirectiveGuid
      this.numDigits = props.numDigits
      this.priceDigits = props.priceDigits
      this.amountDigits = props.amountDigits
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.children = props.children
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.meterageListGuid) this.meterageListGuid = ''
    if (!this.meterageId) this.meterageId = 0
    if (!this.parentId) this.parentId = 0
    if (!this.chapter) this.chapter = 0
    if (!this.meterageCode) this.meterageCode = ''
    if (!this.meterageDisplayCode) this.meterageDisplayCode = ''
    if (!this.meterageName) this.meterageName = ''
    if (!this.meterageFullName) this.meterageFullName = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = 0
    if (!this.price) this.price = 0
    if (!this.amount) this.amount = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.changeDirectiveGuid) this.changeDirectiveGuid = ''
    if (!this.numDigits) this.numDigits = 0
    if (!this.priceDigits) this.priceDigits = 0
    if (!this.amountDigits) this.amountDigits = 0
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.children) this.children = []
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.meterageListGuid = utility.getUuid()
    this.resourceId = this.meterageListGuid
  }
}
