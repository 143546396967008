<template>
  <div class="meterage-list-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="tenderGuid"
            placeholder="标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleImport">导入清单</el-button>
          <el-button type="primary" @click="handleSync">同步台账</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <spread
      ref="mySpread"
      sheet-name="计量清单"
      :header-columns="headerColumns"
    ></spread>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import Spread from '@/components/Spread/MeterageListSpread'
import MeterageListModel from '@/model/MeterageListModel'
import meterageListService from '@/services/meterageListService'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'
import config from '@/config'
import headerColumns from '@/views/MeterageListManage/HeaderColumns'

export default {
  name: 'MeterageListIndex',
  components: {
    Spread,
    MySearchBar
  },
  data () {
    return {
      tenderGuid: '',
      tenders: [],
      headerColumns,
      meterageList: []
    }
  },
  methods: {
    handleImport () {
      if (!this.tenderGuid) {
        this.$message({
          type: 'error',
          message: '请先选择标段！'
        })
        return
      }
      if (this.$refs.mySpread.isValidated()) {
        this._translate()
        console.log(this.meterageList)
        this._pushData()
      } else {
        this.$message({
          type: 'error',
          message: '您输入的数据有误，请检查！'
        })
      }
    },
    handleSync () {
      if (!this.tenderGuid) {
        this.$message({
          type: 'error',
          message: '请先选择标段！'
        })
        return
      }
      this._syncAccountList()
    },
    _translate () {
      this.meterageList = []

      let excelData = this.$refs.mySpread.json()
      console.log('excelData', excelData)
      for (let rowIndex in excelData) {
        let row = excelData[rowIndex]
        let meterageListModel = new MeterageListModel()
        // TODO 补充必填信息
        meterageListModel.tenderGuid = this.tenderGuid
        meterageListModel.changeDirectiveGuid = config.changeDirectiveDefaultGuid

        let hasValue = false

        for (let colIndex in row) {
          let col = row[colIndex]
          if (col.value !== undefined) {
            hasValue = true
            if (this.headerColumns[colIndex].type === 'string') {
              meterageListModel[this.headerColumns[colIndex].key] = col.value.toString()
            } else {
              meterageListModel[this.headerColumns[colIndex].key] = col.value
            }
          }
        }
        hasValue && this.meterageList.push(meterageListModel)
      }
    },
    _pushData () {
      meterageListService.batchAdd(this.meterageList)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '清单导入成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    },
    _syncAccountList () {
      meterageListService.syncAccountList(this.tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '同步成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenders()
  }
}
</script>

<style scoped lang="scss">
.meterage-list-index {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr;
}
</style>
