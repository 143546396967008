const headerColumns = [
  {
    index: 0,
    name: '原始子目号',
    key: 'meterageDisplayCode',
    type: 'string',
    width: 150,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 1,
    name: '子目号',
    key: 'meterageCode',
    type: 'string',
    width: 150,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 2,
    name: '章节',
    key: 'chapter',
    type: 'number',
    width: 100,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 3,
    name: '子目名称',
    key: 'meterageName',
    type: 'string',
    width: 250,
    rules: [
      {
        type: 'required'
      }
    ]
  },
  {
    index: 4,
    name: '单位',
    key: 'unit',
    type: 'string',
    width: 70,
    rules: [
      {
        type: 'custom',
        validator (sheet, rowIndex, colIndex) {
          const myValue = sheet.getValue(rowIndex, colIndex)
          const conditionValue = sheet.getValue(rowIndex, 6)
          if (conditionValue && (myValue === undefined || myValue === null || myValue === '')) {
            return false
          }
          return true
        }
      }
    ]
  },
  {
    index: 5,
    name: '数量',
    key: 'num',
    type: 'number',
    width: 160,
    rules: [
      {
        type: 'custom',
        validator (sheet, rowIndex, colIndex) {
          const myValue = sheet.getValue(rowIndex, colIndex)
          const conditionValue = sheet.getValue(rowIndex, 6)
          if (conditionValue && (myValue === undefined || myValue === null || myValue === '')) {
            return false
          }
          if (conditionValue && isNaN(myValue)) {
            return false
          }
          return true
        }
      }
    ]
  },
  {
    index: 6,
    name: '单价',
    key: 'price',
    type: 'number',
    width: 160,
    rules: [
      {
        type: 'custom',
        validator (sheet, rowIndex, colIndex) {
          const myValue = sheet.getValue(rowIndex, colIndex)
          const conditionValue = sheet.getValue(rowIndex, 6)
          if (conditionValue && (myValue === undefined || myValue === null || myValue === '')) {
            return false
          }
          if (conditionValue && isNaN(myValue)) {
            return false
          }
          return true
        }
      }
    ]
  },
  {
    index: 7,
    name: '金额',
    key: 'amount',
    type: 'number',
    width: 160,
    rules: [
      {
        type: 'custom',
        validator (sheet, rowIndex, colIndex) {
          const myValue = sheet.getValue(rowIndex, colIndex)
          if (myValue && isNaN(myValue)) {
            return false
          }
          return true
        }
      }
    ]
  },
  {
    index: 8,
    name: '数量位数',
    key: 'numDigits',
    type: 'number',
    width: 100,
    rules: [
      {
        type: 'required'
      },
      {
        type: 'number'
      }
    ]
  },
  {
    index: 9,
    name: '单价位数',
    key: 'priceDigits',
    type: 'number',
    width: 100,
    rules: [
      {
        type: 'required'
      },
      {
        type: 'number'
      }
    ]
  },
  {
    index: 10,
    name: '金额位数',
    key: 'amountDigits',
    type: 'number',
    width: 100,
    rules: [
      {
        type: 'required'
      },
      {
        type: 'number'
      }
    ]
  }
]

export default headerColumns
